import React from "react"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {Layout} from "../components/Layout"
import {HomePage} from "."
import {Page} from "../components/PrivateRoutePage"
import {TextCenter} from "../features/diy/components/Shared"
import {getTheme} from "../theme"
import {ResponsiveContainer, SectionPreTitle, SectionTitle} from "../components/Shared"
import rgbDual2VideoMP4 from "../data/videos/recalboxrgbdual2-quick-7min.mp4-processed.mp4"
import rgbDual2Poster from "../data/images/rgbdual2/rrgbd2-video-poster.jpg"
import rgbDual2Jumbo from "../data/images/rgbdual2/rgbdual2-jumbo.jpg"
import {Box, Button} from "@material-ui/core"


export const RGBDual2 = () => {
    const {t, i18n} = useTranslation()
    return <Layout
        title={"Recalbox RGB Dual 2"}
        description={`${t("rrgbd2^Recalbox returns with its latest hardware project to enchant your CRT screens: the Recalbox RGB Dual 2!")} ${t("rgbdual^Now on Kickstarter")}`}
        imageUrl={rgbDual2Jumbo}
        >
        <ResponsiveContainer>
            <TextCenter>
                <SectionTitle>
                    Recalbox RGB Dual 2
                </SectionTitle>
                <SectionPreTitle>
                    {t("rrgbd2^Recalbox returns with its latest hardware project to enchant your CRT screens: the Recalbox RGB Dual 2!")}
                </SectionPreTitle>

                <Box marginTop={2}>
                <Button variant={"outlined"} color={"secondary"} target={"_blank"}
                        href={"https://www.kickstarter.com/projects/recalbox/recalbox-rgb-dual-2?ref=6kkdm1"}>
                    {t("rrgbd2^Get yours on Kickstarter!")}</Button>
                </Box>
                <Box marginTop={2} marginBottom={2}>
                    <video controls poster={rgbDual2Poster}
                           css={{width: "100%", height: "100%", objectFit: "cover"}}>
                        <source src={rgbDual2VideoMP4} type="video/mp4"/>
                    </video>
                </Box>


            </TextCenter>
        </ResponsiveContainer>
    </Layout>
}

const getUrl = () => "/recalbox-rgb-dual-2"
export const RGBDual2Page: Page = {
    Page: RGBDual2,
    getUrl,
    parentPage: () => HomePage,
    breadCrumb: {name: "Recalbox RGB Dual 2", link: getUrl()}
}
export default RGBDual2Page.Page